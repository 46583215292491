import React, { useState } from "react"
import Img from "gatsby-image"
import { graphql } from "gatsby"

import { Layout } from "@/layout"
import {
  Box,
  SEO,
  Heading,
  Text,
  Width,
  List,
  ListItem,
  Span,
  OptinModal,
  Button,
} from "@/components"

export default function OptIn({ data }) {
  const [modalOpened, setModalOpened] = useState(false)
  return (
    <Layout
      headerType="checkout"
      headerTheme="light"
      footerTheme="light"
      squeeze={true}
    >
      <SEO
        title="How to get Unstuck on Guitar"
        description="A Free Training from Sonora Guitar Intensive."
      />
      <Box
        bg="agate"
        height="100%"
        position="relative"
        zIndex="3"
        top="0"
        width="100%"
        display="flex"
        flexDirection="column"
        textAlign="center"
        pt={["7.5rem", null, null, "9.6rem"]}
        pb={["4.8rem", null, null, "0"]}
      >
        <Box width={["100%", null, null, 5 / 12]} textAlign="center" mx="auto">
          <Text variant="subhead" mt="4rem">
            TRUEFIRE INVITES YOU TO
          </Text>

          <Heading
            level="1"
            mb="1rem"
            mt="1rem"
            px={["2rem", "2rem", "2rem", "2rem"]}
          >
            🎉 Break Through the Intermediate Guitar Plateau Today 🎉

          </Heading>
          <Text mb="1rem" mt="1rem" px={["2rem", "2rem", "2rem", "2rem"]}>
            Receive world class 1-1 support to help you find <strong>creative freedom</strong> and navigate
            out of the <strong>intermediate plateau</strong> on guitar.
          </Text>
        </Box>
        <Width
          display="flex"
          width={["100%", 5/12]}
          mx="auto"
          flexDirection={["column-reverse", "row"]}
          pt={["1.2rem", null, null]}
          pb={["0", null, null, "2rem"]}
        >
          <Box
            width={["100%", 12 / 12]}
            textAlign="left"
            mb={["6rem", null, null, "0"]}
            pr={["0", "1rem"]}
          >
            <Box display="flex" flexDirection={["column-reverse"]}>
              <Box>
                <Text mb="2.3rem">
                  In this{" "}
                  <Span fontWeight="600" color="amethyst">
                    Free Training
                  </Span>{" "}
                  you'll learn:
                </Text>
                <List bullets="checkmark">
                  <ListItem>
                    How to achieve fretboard fluency in months, not years.
                  </ListItem>
                  <ListItem>
                    A simple and deliberate approach to breaking out of long
                    learning plateaus.
                  </ListItem>
                  <ListItem>
                    The practice routine that sets advanced players apart from
                    the rest.
                  </ListItem>
                </List>
                <Text mt="3rem" mb="2.3rem">
                  ...plus you'll receive{" "}
                </Text>
                <List bullets="checkmark" mb="2.3rem">
                  <ListItem>A $200 credit toward your tuition </ListItem>
                  <ListItem>A free 50-minute strategy session with our team</ListItem>
                </List>
              </Box>
              <Box mb={["2rem", null, null, "0"]} textAlign={"center"}>
                <Button
                  variant="special"
                  onClick={() => setModalOpened(true)}
                  mb="3rem"
                  mt="1.2rem"
                  padding="2rem 3rem"
                >
                  Send me more information
                </Button>
              </Box>
            </Box>
          </Box>
        </Width>
        <Width
          display="flex"
          textAlign="left"
          flexDirection={["column", "row"]}
          pt="1rem"
          pb={["0", null, null, "10.9rem"]}
        >
          <Box
            width={["100%", null, 4 / 12, null]}
            display="flex"
            flexDirection="row"
            mt={["0rem", null, null, "3rem"]}
          >
            <Box width={[3 / 12, null]} pr="2rem">
              <Box borderRadius="100%" overflow="hidden">
                <Img
                  alt={"david"}
                  fluid={data.davidEImage.childImageSharp.fluid}
                />
              </Box>
            </Box>
            <Text
              width={["100%", null, 9 / 12, null]}
              level="2"
              mb="1rem"
              pr={["2rem", "2rem", "2rem", "4rem"]}
            >
              "This is the most thoughtful, innovative, and intelligent
              methodology for internalizing not only fretboard fluency, but
              music in general." <br></br>
              <strong>- David Engelhardt</strong>
            </Text>
          </Box>
          <Box
            width={["100%", null, 4 / 12, null]}
            display="flex"
            flexDirection="row"
            mt="3rem"
          >
            <Box width={[3 / 12, null]} pr="2rem">
              <Box borderRadius="100%" overflow="hidden">
                <Img
                  alt={"david"}
                  fluid={data.erikLImage.childImageSharp.fluid}
                />
              </Box>
            </Box>
            <Text
              width={["100%", null, 9 / 12, null]}
              level="2"
              mb="1rem"
              pr={["2rem", "2rem", "2rem", "4rem"]}
            >
              "I went from someone who can't improvise at all to being able to
              freely play and improvise over any chord changes in 3 months."{" "}
              <br></br>
              <strong>- Erik Luo</strong>
            </Text>
          </Box>
          <Box
            width={["100%", null, 4 / 12, null]}
            display="flex"
            flexDirection="row"
            mt="3rem"
          >
            <Box width={[3 / 12, null]} pr="2rem">
              <Box borderRadius="100%" overflow="hidden">
                <Img
                  alt={"kevin"}
                  fluid={data.katieBImage.childImageSharp.fluid}
                />
              </Box>
            </Box>
            <Text
              width={["100%", null, 9 / 12, null]}
              level="2"
              mb="1rem"
              pr={["2rem", "2rem", "2rem", "4rem"]}
            >
              "It was an incredibly worthwhile investment that helped me break
              out of a years long rut as a writer and player." <br></br>
              <strong>- Katie Boeck</strong>
            </Text>
          </Box>
        </Width>
        {modalOpened && (
          <OptinModal CTA="Send me info" navigateTo="/truefire/training" closeModal={() => setModalOpened(false)}></OptinModal>
          )}
      </Box>
    </Layout>
  )
}

export const optInQuery = graphql`
  query {
    trainingImage: file(relativePath: { eq: "training.png" }) {
      childImageSharp {
        fluid(maxWidth: 824, quality: 100) {
          ...GatsbyImageSharpFluid_withWebp_tracedSVG
        }
      }
    }
    davidEImage: file(relativePath: { eq: "davide.png" }) {
      childImageSharp {
        fluid(maxWidth: 824, quality: 100) {
          ...GatsbyImageSharpFluid_withWebp_tracedSVG
        }
      }
    }
    kevinSImage: file(relativePath: { eq: "kevins.png" }) {
      childImageSharp {
        fluid(maxWidth: 824, quality: 100) {
          ...GatsbyImageSharpFluid_withWebp_tracedSVG
        }
      }
    }
    miltonPImage: file(relativePath: { eq: "miltonp.png" }) {
      childImageSharp {
        fluid(maxWidth: 824, quality: 100) {
          ...GatsbyImageSharpFluid_withWebp_tracedSVG
        }
      }
    }
    katieBImage: file(relativePath: { eq: "katieb.png" }) {
      childImageSharp {
        fluid(maxWidth: 824, quality: 100) {
          ...GatsbyImageSharpFluid_withWebp_tracedSVG
        }
      }
    }
    erikLImage: file(relativePath: { eq: "erikl.png" }) {
      childImageSharp {
        fluid(maxWidth: 824, quality: 100) {
          ...GatsbyImageSharpFluid_withWebp_tracedSVG
        }
      }
    }
  }
`
